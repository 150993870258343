"use client";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import Loading from "./shared/components/Loading";

export default function Home() {
  const nagivate = useRouter();
  useEffect(() => {
    nagivate.push("/auth/login");
  }, []);
  return <Loading.Full />;
}
