import React from "react";
import Image from "next/image";
import { Spinner } from "flowbite-react";

const LoadingFull = () => (
  <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex items-center justify-center  backdrop-blur-md">
    <div className="flex space-x-2 justify-center items-center h-screen dark:invert">
      <div className="rounded-full animate-bounce [animation-delay:-0.3s]">
        <Image
          src="/images/Shapes/Shapes-1.svg"
          alt="Logo da PSI"
          width={30}
          height={30}
        />
      </div>
      <div className="rounded-full animate-bounce [animation-delay:-0.15s]">
        <Image
          src="/images/Shapes/Shapes-2.svg"
          alt="Logo da PSI"
          width={30}
          height={30}
        />
      </div>
      <div className="rounded-full animate-bounce">
        <Image
          src="/images/Shapes/Shapes-3.svg"
          alt="Logo da PSI"
          width={30}
          height={30}
        />
      </div>
    </div>
  </div>
);

const Loading = () => (
  <div className="flex items-center justify-center  ">
    <div className="flex space-x-2 justify-center items-center h-screen dark:invert">
      <div className="rounded-full animate-bounce [animation-delay:-0.3s]">
        <Image
          src="/images/Shapes/Shapes-1.svg"
          // className="animate-spin animate-infinite animate-"
          alt="Logo da PSI"
          width={30}
          height={30}
        />
        {/* <LuDot className="text-primary-500" /> */}
      </div>
      <div className="rounded-full animate-bounce [animation-delay:-0.15s]">
        <Image
          src="/images/Shapes/Shapes-2.svg"
          // className="animate-spin animate-infinite"
          alt="Logo da PSI"
          width={30}
          height={30}
        />
        {/* <LuDot className="text-primary-500" /> */}
      </div>
      <div className="rounded-full animate-bounce">
        <Image
          src="/images/Shapes/Shapes-3.svg"
          // className="animate-spin animate-infinite"
          alt="Logo da PSI"
          width={30}
          height={30}
        />
        {/* <LuDot className="text-primary-500" /> */}
      </div>
    </div>
  </div>
);

const SpinnerLoading = () => <Spinner color="info" size={"xs"} />;
export default { Root: Loading, Full: LoadingFull, Spinner: SpinnerLoading };
